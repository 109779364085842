<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-3">
        <ChannelInfo :passChannel="passChannel" />
        <div class="g-summary">
            <v-row class="mx-0 justify-center w-100 mt-7">
                <BtnBettingComponent v-for="i in 3" :key="i" :passChannel="passChannel" :passIndex="i"
                    :passPlaceCoin="bettingMoney" :passButtonInfo="getButton(i)" :passSelectedCoin="selectedCoin"
                    :passActiveItem="activeItem" v-on:handleSelectButton="_allowBtnBet" />
            </v-row>
            <v-row class="mx-0 justify-center w-100 mt-7">
                <BtnBettingComponent v-for="j in  range(4, 6)" :key="j" :passChannel="passChannel" :passIndex="j"
                    :passPlaceCoin="bettingMoney" :passButtonInfo="getButton(j)" :passSelectedCoin="selectedCoin"
                    :passActiveItem="activeItem" v-on:handleSelectButton="_allowBtnBet" />
            </v-row>
            <v-row class="mx-0 justify-center w-100 mt-7">
                <BtnBettingComponent v-for="k in  range(7, 9)" :key="k" :passChannel="passChannel" :passIndex="k"
                    :passPlaceCoin="bettingMoney" :passButtonInfo="getButton(k)" :passSelectedCoin="selectedCoin"
                    :passActiveItem="activeItem" v-on:handleSelectButton="_allowBtnBet" />
            </v-row>

        </div>
        <!-- Group Coin -->
        <CoinButton :passGetButton="passGetButton" :passChannel="passChannel" :passPlaceCoin="bettingMoney"
            v-on:handleInputMonay="_inputAmount" />
        <SubmitBtn  
            :passCheckAllowSubmit="_checkAllowSubmit"
            v-on:handleSubmitBtn="_submitBetting"
            v-on:handleClearBtn="_clearMoney"/>
        <LimitBetting />
    </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import BtnBettingComponent from '../../../../components/Apong/BtnBettingComponent.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import CoinButton from '../../../../components/CoinBettingButton.vue';
import ChannelInfo from '../../../../components/ChannelInfo.vue';
import LimitBetting from '../../../../components/LimitBetting.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, BtnBettingComponent, SubmitBtn },
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            valueTiger: "",
            resultTiger: '',
            valueDragon: '',
            resultDragon: '',
            selectedBtnClassSmall: false,
            selectedBtnClassTie: false,
            selectedBtnClasslarge: false,
            countDown: {},
            selectedBtnClassOne: false,
            selectedBtnClassTwo: false,
            selectedBtnClassThree: false,
            selectedBtnClassFour: false,
            selectedBtnClassFive: false,
            selectedBtnClassSix: false,
            selectedBtnClassTwoThree: false,
            selectedBtnClassThreeFour: false,
            selectedBtnClassFourFive: false,
            selectedCoin: false,
            activeItem: null
        }
    },
    watch: {
    },
    computed: {
        oneButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        twoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        threeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        fourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        fiveButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        sixButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[5]
            else return {}
        },
        twoThreeBtn() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[6]
            else return {}
        },
        threeFourBtn() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[7]
            else return {}
        },
        fourFiveBtn() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[8]
            else return {}
        },
        _checkAllowSubmit() {
            if ( this.bettingMoney > 0
                && ( this.selectedBtnClassSix || this.selectedBtnClassFive || this.selectedBtnClassFour
                    || this.selectedBtnClassThree || this.selectedBtnClassTwo || this.selectedBtnClassOne 
                    || this.selectedBtnClassTwoThree || this.selectedBtnClassThreeFour || this.selectedBtnClassFourFive)
                ) return true;
            else  return false;
        },
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },

    methods: {

        getButton(index) {
            switch (index) {
                case 1:
                    return this.oneButton;
                case 2:
                    return this.twoButton;
                case 3:
                    return this.threeButton;
                case 4:
                    return this.fourButton;
                case 5:
                    return this.fiveButton;
                case 6:
                    return this.sixButton;
                case 7:
                    return this.twoThreeBtn;
                case 8:
                    return this.threeFourBtn;
                case 9:
                    return this.fourFiveBtn;
                default:
                    return ''
            }
        },
        range: function (start, end) {
            return Array(end - start + 1).fill().map((val, i) => start + i)
        },

        _inputAmount(money) {
  
                this.selectedCoin = true
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)

        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassSmall = false;
            this.selectedBtnClassOne = false;
            this.selectedBtnClasslarge = false;
            this.selectedBtnClassTwo = false;
            this.selectedBtnClassThree = false;
            this.selectedBtnClassFour = false;
            this.selectedBtnClassFive = false;
            this.selectedBtnClassSix = false;
            this.selectedBtnClassTwoThree = false;
            this.selectedBtnClassThreeFour = false;
            this.selectedBtnClassFourFive = false;
            this.selectedCoin = false;
            this.activeItem = null;
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            console.log(selectedBtn);
            if (!this.selectedCoin) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'b1':
                    this.activeItem = 1;
                    this.selectedButton = this.oneButton;
                    this.selectedBtnClassOne = true;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b2':
                    this.activeItem = 2;
                    this.selectedButton = this.twoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = true;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b3':
                    this.activeItem = 3;
                    this.selectedButton = this.threeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = true;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b4':
                    this.activeItem = 4;
                    this.selectedButton = this.fourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = true;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b5':
                    this.activeItem = 5;
                    this.selectedButton = this.fiveButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = true;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b6':
                    this.activeItem = 6;
                    this.selectedButton = this.sixButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = true;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b7':
                    this.activeItem = 7;
                    this.selectedButton = this.twoThreeBtn;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = true;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b8':
                    this.activeItem = 8;
                    this.selectedButton = this.threeFourBtn;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = true;
                    this.selectedBtnClassFourFive = false;
                    break;
                case 'b9':
                    this.activeItem = 9;
                    this.selectedButton = this.fourFiveBtn;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    this.selectedBtnClassTwoThree = false;
                    this.selectedBtnClassThreeFour = false;
                    this.selectedBtnClassFourFive = true;
                    break;
            }
        },
        ...mapMutations("$_dashboard", [
            "UPDATE_ABONG_CARD",
            "RESET_ABONG_CARD"
        ]),
    }
}
</script>
<style scoped>
@media(min-width:960px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }



    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }



    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width: 150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}


.range-betting-header {
    width: 100%;
    height: 66%;
    padding-top: 10px;
    background-color: rgba(32, 218, 88, 0.2);
    border-bottom: 3px solid rgb(218, 165, 32);
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
}

.range-betting-payout {
    text-align: center;
    padding-top: 10px
}

.img-coin-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}

.max-label {
    width: 100%;
    height: 30px;
    margin-bottom: -9px;
}
</style>