<template>
    
    <div class=" side-section-range large-section pb-1 side-1 range-betting-container"
        :class="[ passIndex == '2' || passIndex == '5' ? 'mx-5': '' || passIndex == '8' ? 'mx-5' : '', passActiveItem == passIndex 
        ?'on-active-btn' :'' ] "
        :style="!passPlaceCoin || !passChannel.is_open ? 'opacity:0.8;' : ''"
        @click="_selectButton(passIndex)">
        <div class="range-betting-payout font-weight-bold" :class="passButtonInfo.color=='cir-red'?'banker-color' : 'player-color' ">{{ passButtonInfo ? `${passButtonInfo.name} ` : '' }}<!--{{ passButtonInfo ? `${passButtonInfo.desc} ` : '' }}--></div>
        <div class="text-display-payout">{{ passButtonInfo ? passButtonInfo.payout_display : ''}}</div>
        
    </div>

    
   
</template>
<script>
import { mapGetters } from "vuex";
// import CoinInBtn from '../CoinInBtn.vue';

import "@/assets/css/homepage.css";
export default {
    // components: { CoinInBtn, },
    props: [
        'passButtonInfo',
        'passChannel',
        'passPlaceCoin',
        'passIndex',
        "passSelectedCoin",
        'passActiveItem'
    ],
    data() {
        return {
            selectedButton: '',
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {

        async _selectButton(index){
            let data = 'b'+index;
            // this.selectedButton = data;
            await this.$emit("handleSelectButton", data);
        },
        _detectImg(index) {
            console.log(index);
            switch (index) {
                case "1":
                    return '/images/6number/1.png';
                case "2":
                    return '/images/6number/2.png';
                case "3":
                    return '/images/6number/3.png';
                case "4":
                    return '/images/6number/4.png';
                case "5":
                    return '/images/6number/5.png';
                case "6":
                    return '/images/6number/6.png';
                case "2-3":
                    return '/images/6number/2-3.png';
                case "3-4":
                    return '/images/6number/3-4.png';
                case "4-5":
                    return '/images/6number/4-5.png';
                default:
                    return ''
            }
        },

    }
}
</script>
<style scoped>
@media(max-width:768px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
    
.range-betting-container {
    width: 23%;
    height: 90px;
    border-radius: 5px;
    background-color: rgb(0, 102, 153, .1);
    border: 1px solid rgb(218, 165, 32);
    position: relative;
}

.range-betting-container-active {
    width: 23%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 3px solid grey;
    transform: scale(1.04);
    position: relative;
}
}
@media(min-width:768px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
    
.range-betting-container {
    width: 23%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 1px solid rgb(218, 165, 32);
    position: relative;
}

.range-betting-container-active {
    width: 23%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 3px solid grey;
    transform: scale(1.04);
    position: relative;
}
}
@media(min-width:960px) {
    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 70px;
    }
    
.range-betting-container {
    width: 29%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 1px solid rgb(218, 165, 32);
    position: relative;
}

.range-betting-container-active {
    width: 29%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 3px solid grey;
    transform: scale(1.04);
    position: relative;
}
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-range {
        width: 50px;
        height: auto;
        margin: auto;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        text-align: center;
        justify-content: center;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .money-in-coin-range-position {
        margin-top: -34px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
        
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }
    
.range-betting-container {
    width: 29%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 1px solid rgb(218, 165, 32);
    position: relative;
}

.range-betting-container-active {
    width: 29%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 3px solid grey;
    transform: scale(1.04);
    position: relative;
}
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}


.range-betting-container:hover {
    cursor: pointer;
}

.range-betting-header {
    width: 100%;
    height: 66%;
    padding-top: 10px;
    background-color: rgba(32, 218, 88, 0.2);
    border-bottom: 3px solid rgb(218, 165, 32);
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
}
.banker-color{
    color:#ff0e1a;
}
.player-color{
    color: blue;
}
.range-betting-payout {
    text-align: center;
    margin-top:-2px;
    font-size:1.3rem;
    padding-top: 6px;
    text-shadow: 0 1px 0 #ffffff,
               0 1px 0 #ffffff,
               0 1px 0 #ffffff,
               0 1px 0 #ffffff,
               0 1px 0 #ffffff,
               0 3px 1px rgba(77, 74, 74, 0.1),
               0 0 3px rgba(133, 122, 122, 0.1),
               0 1px 3px rgba(157, 148, 148, 0.3),
               0 3px 1px rgba(177, 172, 172, 0.2),
               0 1px 3px rgba(160, 156, 156, 0.25),
               0 3px 3px rgba(177, 176, 176, 0.2),
               0 3px 1px rgba(165, 162, 162, 0.15);
              

}
.text-display-payout{
    text-align: center;
    font-size: 1rem;
}

.img-coin-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}
.max-label{
    width:100%; 
    height:30px; 
    margin-bottom:-9px;
}

@media screen and (min-width: 1904px){
    .text-display-payout{
        font-size: 1rem !important;
    }
}
@media screen and (min-width:1280px) {
    .text-display-payout{
        font-size: 1rem !important;
    }
}
@media screen and (min-width:982px) {
    .text-display-payout{
        font-size: .80rem;
    }
}
@media screen and (min-width:960px) {
    .text-display-payout{
        font-size: .80rem;
    }
}
@media screen and (max-width:768px) {
    .text-display-payout{
        font-size: .80rem;
    }
}
@media screen and (max-width: 390px){
    .text-display-payout{
        font-size: .75rem;
    }
}
@media screen and (max-width: 375px){
    .range-betting-payout{font-size: .90rem;}
}
</style>