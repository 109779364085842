<template>
    <div class="justify-content-center row text-center mx-2 my-6 px-0 ">
        <div class="row ma-0">
            <div class="row coin-container ma-0 px-0 gp-scroll ">
                <div class="col-2 ma-0 pa-0 pr-2 hold-coin">
                <img :src="_detectCoin(33)" class="btn-coin text-center px-0">
                <div text :style="`color:${_detectColor(5)};`" class="text btn-bet-circle prevent-select pr-2">
                    {{
                        userInfor && userInfor.currency_type == 2
                        ? formatBtnCurrencyKh(passPlaceCoin) :
                        formatBtnCurrency(passPlaceCoin)
                    }}
                </div>
            </div>
                <div v-for="(button, index) in passGetButton" :key="button._id" class=" ma-0 p-0 col-2 ">
                    <div @click="_inputAmount(button.amount)">
                        <img :src="_detectCoin(index)" class="btn-coin text-center">
                        <div text :style="`color:${_detectColor(index)};`" class="text btn-bet-circle prevent-select">
                            {{ button.label }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
    props: [
        'passGetButton',
        'passChannel',
        'passPlaceCoin',
    ],
    data() {
        return {
            selectedButton: {},
        }
    },
    computed: {
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        _detectColor(index) {
            switch (index) {
                case 0:
                    return 'black';
                case 1:
                    return 'black';
                case 2:
                    return 'black';
                case 3:
                    return 'black';
                case 4:
                    return 'black';
                case 5:
                    return 'black';
                case 6:
                    return 'black';
                case 7:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectCoin(index) {
            switch (index) {
                case 0:
                    return '/images/coins/fg9-coin.png';
                case 1:
                    return '/images/coins/fg9-coin.png';
                case 2:
                    return '/images/coins/fg9-coin.png';
                case 3:
                    return '/images/coins/fg9-coin.png';
                case 4:
                    return '/images/coins/fg9-coin.png';
                case 5:
                    return '/images/coins/fg9-coin.png';
                case 6:
                    return '/images/coins/fg9-coin.png';
                case 7:
                    return '/images/coins/fg9-coin.png';
                case 33:
                    return '/images/coins/fg9-coin.png';
                default:
                    return ''
            }
        },
        _inputAmount(money) {

            if (!this.passChannel.is_open) {
                this.$toastr.e(this.$t("title.gameIsClose"));
                return false;
            }
            this.bettingMoney = parseInt(this.bettingMoney);
            this.bettingMoney += parseInt(money)
            this.$emit("handleInputMonay", money);
        },
        formatCoinCurrency(amount) {
            switch (amount) {
                case 1000:
                    return '1ព';
                case 5000:
                    return '5ព';
                case 10000:
                    return '1ម';
                case 20000:
                    return '2ម';
                case 50000:
                    return '5ម';
                case 100000:
                    return '10ម';
                case 200000:
                    return '20ម';
                case 500000:
                    return '50ម';
                case 1000000:
                    return '1ល';
                default:
                    return amount;
            }
        },
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <= 9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <= 999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <= 99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
        ...mapMutations("$_dashboard", [
            "BETTING_DIALOG"
        ]),
    }
}
</script>
<style scoped>
@media(max-width:760px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.2rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-coin-cover {
        width: 100%
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:760px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:960px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.25rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 1rem;
        font-weight: 900;
        text-shadow: 1px 0px 1px black;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

@media(min-width:1280px) {
    .btn-coin {
        width: 100%;
        height: auto;
        position: relative;
        padding: 0.3rem;
        margin: 0px !important;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .money-in-coin-position {
        margin-top: -52px;
        margin-bottom: 25px;
        z-index: 100;
        font-weight: 900;
        font-size: 16px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
        top: 50%;
        left: 50%;
        transform: translate(-51%, -51%);
        padding: 0px;
        margin: 0px !important;
    }
}

.prevent-select {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}
.hold-coin{
    position: sticky; 
    left:0px; z-index: 100; 
    background :#27282a; 
    border-right : 2px dashed white; 
}
.coin-container {
    overflow: auto;
    flex-wrap: nowrap;
    width: 100%;
}
.coin-container:hover{
    cursor: pointer;
}
</style>