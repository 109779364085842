import { render, staticRenderFns } from "./Han2BtnBettingComponent.vue?vue&type=template&id=7f681315&scoped=true&"
import script from "./Han2BtnBettingComponent.vue?vue&type=script&lang=js&"
export * from "./Han2BtnBettingComponent.vue?vue&type=script&lang=js&"
import style0 from "./Han2BtnBettingComponent.vue?vue&type=style&index=0&id=7f681315&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f681315",
  null
  
)

export default component.exports