<template>
  <!-- eslint-disable -->
  <div>
    <v-btn 
      @click="print(todayReport._id)"
      x-small color="primary"
      id="print-button"
    >
    {{$t('title.print')}}
    </v-btn >
    <table :id="`printMe${todayReport._id}`" hidden>
      <tbody>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            cf9
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            Bet ID: {{ todayReport._id }}
          </td>
        </tr>
        <br />
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <table>
              <tbody>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.fight') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.fight_no ? todayReport.fight_no : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.type') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{ todayReport.betting ? todayReport.betting : "" }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.time') }}</td>
                  <td style="padding: 5px; text-align: left">
                    {{todayReport.time}}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.cast') }}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.result != null ? todayReport.result : todayReport.amount != null ? todayReport.amount : todayReport}}</span>
                  </td>
                </tr>
                <tr>
                  <td style="padding: 5px; text-align: left">{{ $t('title.win_lose') }}</td>
                  <td style="padding: 5px; text-align: left">
                    <span>{{todayReport.is_win ? $t('title.win') : todayReport.is_win == false ? $t('title.lose') : todayReport.result1_name ? $t('title.cancel'): '???'}}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td style="padding: 5px; text-align: left">Win:</td>
                  <td style="padding: 5px; text-align: left">
                    {{
                      current.amount_win || current.amount_win === 0
                        ? parseInt(current.amount_win).toLocaleString("en")
                        : "???"
                    }}
                  </td>
                </tr> -->
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <td style="padding: 5px; text-align: center" colspan="2">
            -------------------------------------
          </td>
        </tr>
        <br />
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  props: ["todayReport"],
  methods: {
    async print(id) {
      // Pass the element id here
      await this.$htmlToPaper(`printMe${id}`);
    },
  },
};
</script>