<template>
    <div class="view-bet-sec pt-0 px-0 pb-2 px-md-0 pt-2">
        <ChannelInfo
            :passChannel="passChannel"
        />
            <div class="g-summary">
                <div class="sides-wrapper gp-panel-bg rounded">
                    <div class="d-flex sides px-2 justify-center w-100">
                    </div>
                    <div class=" row w-100 justify-center mx-auto mt-2">
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassOne? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedOne')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(0)" class="betting-coin-range" style="margin-top:0.8px" />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{oneButton ? `1 : ${ oneButton.payout_display } ` : 'x : x'}}</div>
               
                        </div>
                        <div class="side-section-range large-section mx-5 pb-1 side-1 range-betting-container mx-1"
                            :class="selectedBtnClassTwo? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedTwo')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(1)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{ twoButton ? `1 : ${ twoButton.payout_display } ` : 'x : x'
                            }}</div>
                           
                        </div>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassThree? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedThree')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(2)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{threeButton ? `1 : ${ threeButton.payout_display } ` : 'x : x' }}</div>
                          
                        </div>
                    </div>
                    <div class="d-flex sides justify-center w-100 mt-7">
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassFour? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedFour')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(3)" class="betting-coin-range " style="margin-top:0.8px" />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{fourButton ? `1 : ${ fourButton.payout_display } ` : 'x : x'}}</div>
                          
                        </div>
                        <div class="side-section-range large-section pb-1 side-1 mx-5 range-betting-container"
                            :class="selectedBtnClassFive? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedFive')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(4)" class="betting-coin-range " />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{fiveButton ? `1 : ${ fiveButton.payout_display } ` : 'x : x' }}</div>
                           
                        </div>
                        <div class="side-section-range large-section pb-1 side-1 range-betting-container"
                            :class="selectedBtnClassSix? 'range-betting-container-active' : ''"
                            :style="!selectedCoin || !passChannel.is_open ? 'opacity:0.5;' : ''"
                            @click="_allowBtnBet('selectedSix')">
                            <div class=" text-center range-betting-header">
                                <img :src="_detectImg(5)" class="betting-coin-range " style="margin-top:1px" />
                            </div>
                            <div class="range-betting-payout font-weight-bold">{{sixButton ? `1 : ${ sixButton.payout_display } ` : 'x : x'
                            }}</div>
                           
                        </div>
                    </div>

                </div>
            </div>
            <CoinButton 
                :passGetButton="passGetButton" 
                :passChannel="passChannel"
                :passPlaceCoin="bettingMoney"
                v-on:handleInputMonay="_inputAmount"/>
                <SubmitBtn 
                    :passCheckAllowSubmit="_checkAllowSubmit" 
                    v-on:handleSubmitBtn="_submitBetting"
                    v-on:handleClearBtn="_clearMoney" />
            <LimitBetting/>
        </div>
</template>

<script>
import "@/assets/css/video-js.css";
import "@/assets/css/homepage.css";
import ChannelInfo from '../../../../components/ChannelInfo.vue';
import CoinButton from '../../../../components/CoinBettingButton.vue';
import LimitBetting from '../../../../components/LimitBetting.vue';
import SubmitBtn from '../../../../components/SubmitBtn.vue';
import { mapGetters } from "vuex";
export default {
    components: { CoinButton, ChannelInfo, LimitBetting, SubmitBtn},
    props: ["passChannel",
        "passCurrentBetting",
        'passChannelStatus',
        'passGetButton',
        'passBettingTypeMaster',
        'passWeight',
        'passSelectedChannel'
    ],
    data() {
        return {
            selectedButton: {},
            bettingMoney: 0,
            valueTiger: "",
            resultTiger: '',
            valueDragon: '',
            resultDragon: '',
            selectedBtnClassSmall: false,
            selectedBtnClassTie: false,
            selectedBtnClasslarge: false,
            countDown: {},
            selectedBtnClassOne: false,
            selectedBtnClassTwo: false,
            selectedBtnClassThree: false,
            selectedBtnClassFour: false,
            selectedBtnClassFive: false,
            selectedBtnClassSix: false,
            selectedCoin: false,
        }

    },

    computed: {
        oneButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[0]
            else return {}
        },
        twoButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[1]
            else return {}
        },
        threeButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[2]
            else return {}
        },
        fourButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[3]
            else return {}
        },
        fiveButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[4]
            else return {}
        },
        sixButton() {
            if (this.passBettingTypeMaster)
                return this.passBettingTypeMaster[5]
            else return {}
        },
        _checkAllowSubmit(){
            if( this.bettingMoney > 0 && ( this.selectedBtnClassSix || this.selectedBtnClassFive || this.selectedBtnClassFour || this.selectedBtnClassThree || this.selectedBtnClassTwo || this.selectedBtnClassOne ))return true;
            else return false
        },
        ...mapGetters("$_dashboard", {
            dialogBetting: "getDialogBetting",
            amountMin: "getAmountMin",
            amountMax: "getAmountMax",
            getCountdown:"getCountdown"
        }),
        ...mapGetters("$_modules", {
            userInfor: "getUserInfo",
        }),
    },
    methods: {
        formatBtnCurrencyKh(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "ព";
                case (amount >= 10000 && amount <=999999):
                    return amount / 10000 + "ម";
                case (amount >= 1000000 && amount <=99999999999):
                    return amount / 1000000 + "ល";
                default:
                    return amount
            }
        },
        formatBtnCurrency(amount) {
            switch (true) {
                case (amount >= 1000 && amount <=9999):
                    return amount / 1000 + "K";
                default:
                    return amount
            }
        },
       
      

        _detectCurrentMoneyCoinlarge(money) {
            switch (true) {
                case money < 5:
                    return '/images/coins/blue.png';
                case money < 10:
                    return '/images/coins/pink.png';
                case money < 20:
                    return '/images/coins/cyan.png';
                case money < 50:
                    return '/images/coins/red.png';
                case money < 100:
                    return '/images/coins/orange.png';
                case money < 99999999:
                    return '/images/coins/grey.png';
                default:
                    return ''
            }
        },
        _detectCurrentMoneyColorlarge(money) {
            switch (true) {
                case money < 5:
                    return 'black';
                case money < 10:
                    return 'black';
                case money < 20:
                    return 'black';
                case money < 50:
                    return 'black';
                case money < 100:
                    return 'black';
                case money < 99999999:
                    return 'black';
                default:
                    return ''
            }
        },
        _detectImg(index) {
            switch (index) {
                case 0:
                    return '/images/klaklok/0001.png';
                case 1:
                    return '/images/klaklok/0002.png';
                case 2:
                    return '/images/klaklok/0003.png';
                case 3:
                    return '/images/klaklok/0004.png';
                case 4:
                    return '/images/klaklok/0005.png';
                case 5:
                    return '/images/klaklok/0006.png';
                default:
                    return ''
            }
        },
        _inputAmount(money) {
            
                this.selectedCoin = true;
                this.bettingMoney = parseInt(this.bettingMoney);
                this.bettingMoney += parseInt(money)

        },
        _clearMoney() {
            this.bettingMoney = 0;
            this.selectedBtnClassSmall = false;
            this.selectedBtnClassOne = false;
            this.selectedBtnClasslarge = false;
            this.selectedBtnClassTwo = false;
            this.selectedBtnClassThree = false;
            this.selectedBtnClassFour = false;
            this.selectedBtnClassFive = false;
            this.selectedBtnClassSix = false;
            this.selectedCoin = false;
            
        },
        _submitBetting() {
            let submitBettingData = {
                type_id: this.selectedButton._id,
                amount: this.bettingMoney
            };
            this.$emit("sentSubmitBetting", submitBettingData);
            this._clearMoney();
        },
        _allowBtnBet(selectedBtn) {
            if (!this.selectedCoin ) {
                this.$toastr.e(this.$t('title.selectCoin'));
                return false;
            }
            switch (selectedBtn) {
                case 'selectedOne':
                    this.selectedButton = this.oneButton;
                    this.selectedBtnClassOne = true;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    
                    break;
                case 'selectedTwo':
                    this.selectedButton = this.twoButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = true;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    
                    break;
                case 'selectedThree':
                    this.selectedButton = this.threeButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = true;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    
                    break;
                case 'selectedFour':
                    this.selectedButton = this.fourButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = true;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = false;
                    
                    break;
                case 'selectedFive':
                    this.selectedButton = this.fiveButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = true;
                    this.selectedBtnClassSix = false;
                    
                    break;
                case 'selectedSix':
                    this.selectedButton = this.sixButton;
                    this.selectedBtnClassOne = false;
                    this.selectedBtnClassTwo = false;
                    this.selectedBtnClassThree = false;
                    this.selectedBtnClassFour = false;
                    this.selectedBtnClassFive = false;
                    this.selectedBtnClassSix = true;
                    break;
            }
        },
    }
}
</script>
<style scoped>
@media(min-width:960px) {
    .money-in-coin-range-position {
        margin-top: -37px;
        margin-bottom: 14px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .betting-coin-range {

        width: 60px;
        height: 60px;
        margin: auto;
        /* margin-top: -50px; */
        z-index: 1000 important
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-small {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-large {
        width: 40px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 45px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }


    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -29.5px;
        margin-bottom: 25px;
        margin-left: -2px;
        z-index: 100;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        font-size: 13px;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 1px;
        left: -16px;
        font-size: 12.5px;
        font-weight: 600;
        text-shadow: 1px 0px 1px black;
        width: 68px;
    }
}

@media(min-width:1280px) {

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .betting-coin-small {
        width: 50px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .betting-coin-range {

        width: 60px;
        height: 60px;
        margin: auto;
    }

    .betting-coin-large {
        width: 60px;
        height: auto;
        margin: auto;
        margin-top: 20px;
    }

    .btn-coin {
        width: 60px;
        height: auto;
        position: relative;
    }

    .btn-clear-money {
        background: rgb(201, 201, 214);
        background: linear-gradient(180deg, rgba(201, 201, 214, 1) 0%, rgba(103, 103, 106, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        justify-content: center;
        width:150px;
    }

    .cross-icon {
        margin-top: -4px;
        margin-right: 2.5px;
        font-size: 17px;
    }

    .btn-submit-money {
        background: rgb(110, 179, 241);
        background: linear-gradient(180deg, rgba(110, 179, 241, 0.9108018207282913) 0%, rgba(25, 72, 166, 1) 100%);
        border-width: 0.5 !important;
        border-color: #133375 !important;
        border-radius: 6px;
        padding: 6px 10px;
        color: white;
        width:150px;
        justify-content: center;
    }

    .span-icon {
        border-radius: 7px;
        padding: 11.5px 20px;
    }

     .on-active-btn {
        /* background-color: #fec84e; */
        background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
        border: 4px solid gold;
    }

    .side-section:hover {
        cursor: pointer;
    }

    .btn-coin-cover:hover {
        cursor: pointer;
    }

    .prevent-select {
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
    }

    .money-in-coin-position {
        margin-top: -35px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
    }

    .money-in-coin-range-position {
        margin-top: -37px;
        margin-bottom: 15px;
        z-index: 100;
        font-weight: 900;
        width: 100%;
        text-align: center;
        font-size: 12px;
        text-shadow: 1px 0px black;
    }

    .btn-bet-circle {
        font-family: 'khmer mef1';
        position: absolute;
        top: 7px;
        left: -8px;
        font-size: 16px;
        font-weight: 900;
        text-shadow: 1px 1px 1px black;
    }

    .side-section {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 34%;
        flex: 0 0 34%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
          background-color: #9ea6b48f;
        min-height: 100px;
    }

    .side-section-tie {
        text-align: center;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 29%;
        flex: 0 0 29%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: #9ea6b48f;
    }
}

.open-betting-countdown {
    background-color: #def3f6;
    font-size: 22px;
    font-family: 'khmer mef1';
    font-weight: 900;
    height: 42px;
    text-align: center;
    padding-top: 6px;
    text-shadow: 0.2px 0.2px red;
}

.open-betting {
    color: #28a745;
}

.close-betting {
    color: #dc3545;
}

.range-betting-container {
    width: 18%;
    height: 90px;
    border-radius: 5px;
      background-color: #9ea6b48f;
    border: 1px solid rgb(218, 165, 32);
}

.range-betting-container-active {
    width: 18%;
    height: 90px;
    border-radius: 5px;
    border: 4px solid gold;
        /* background-color: #fec84e; */
    background-image: linear-gradient(315deg, #fec84e 0%, #ffdea82c 90%);
}

.range-betting-container:hover {
    cursor: pointer;
}

.range-betting-header {
    width: 100%;
    height: 77%;
    padding-top: 10px;
    background-color: rgba(32, 218, 88, 0.2);
    border-bottom: 3px solid rgb(218, 165, 32);
    color: lightgoldenrodyellow;
    text-shadow: 1px 0px black;
    padding-top: 0px;
    font-weight: 700;
    font-size: 27px;
}

.range-betting-payout {
    text-align: center;
}

.img-coin-range {
    width: 100%;
    display: grid;
    margin-top: -70px
}
</style>