export default {
  permission_list: 'តារាងសិទ្ធអ្នកប្រើប្រាស់',
  role_list: 'តារាងតួនាទីអ្នកប្រើប្រាស់',
  user_list: 'តារាងអ្នកប្រើប្រាស់',
  menu_list: 'តារាងម៉ឺនុយ',
  create_permission: 'បង្កើតសិទ្ធអ្នកប្រើប្រាស់',
  update_permission: 'កែប្រែសិទ្ធអ្នកប្រើប្រាស់',
  create_role: 'បង្កើតតួនាទីអ្នកប្រើប្រាស់',
  update_role: 'កែប្រែតួនាទីអ្នកប្រើប្រាស់',
  create_user: 'បង្កើតអ្នកប្រើប្រាស់',
  update_user: 'កែប្រែអ្នកប្រើប្រាស់',
  create_menu: 'បង្កើតម៉ឺនុយ',
  update_menu: 'កែប្រែម៉ឺនុយ',
  change_password_user: 'កែប្រែលេខសម្ងាត់អ្នកប្រើប្រាស់',
  welcome: 'សូមស្វាគមន៍:',
  account_balance: 'សមតុល្យគណនេយ្យ',
  user_role: 'តួនាទីអ្នកប្រើប្រាស់',
  No:'លេខ',
  your_balance:'សមតុល្យគណនី',
  home:'ទំព័រដើម',
  sbc:'អេស.ប៊ី.ស៊ី',
  status:'ស្ថានភាព',
  time:'ពេលវេលា',
  result:'លទ្ធផល',
  five_k:'5 ពាន់',
  ten_k:'1 ម៉ឺន',
  twenty_k:'2 ម៉ឺន',
  five_k1:'5 ម៉ឺន',
  payout:'សងត្រឡប់',
  ten_100k:'10 ម៉ឺន',
  twenty_200k:'20 ម៉ឺន',
  five_500k:'50 ម៉ឺន',
  one_1m:'1 លាន',
  current_betting:'តារាងភ្នាល់',
  today_report:'របាយការណ៍ភ្នាល់',
  fight:'លេខភ្នាល់',
  type:'ប្រភេទ',
  amount:'ទឹកប្រាក់',
  total_amount: 'ទឹកប្រាក់សរុប',
  // thin:"ស្តើង",
  // thick:"ក្រាស់",
  thin:"Under",
  thick:"Over",
  red:'ក្រហម',
  blue:'ខៀវ',
  action:'សកម្មភាព',
  my_profile:'ព័ត៌មានរបស់ខ្ញុំ',
  persional_info:'ព័ត៌មានផ្ទាល់ខ្លួន',
  change_password:'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
  betLimit:'ដែនកំណត់ភ្នាល់',
  result_betting:'លទ្ធផលភ្នាល់',
  result_win:'លទ្ធផលឈ្នះ',
  report:'របាយការណ៍',
  MERON:'ក្រហម',
  WALA:'ខៀវ',
  tie:'ស្មើ',
  DRAW:"ស្មើ",
  cancel:'បោះបង់',
  pleaseBet:'សូមភ្នាល់',
  login:'ចូលប្រព័ន្ធ',
  deposit:'ដាក់ប្រាក់',
  withdraw:'ដកប្រាក់',
  betting:'ភ្នាល់',
  all_betting:'ភ្នាល់(All)',
  channel:"ប្រភេទហ្គេម",
  channel1:'ប៉ុស្តិ៍ 1',
  channel2:'ប៉ុស្តិ៍ 2',
  channel3:'ប៉ុស្តិ៍ 3',
  channel4:'ប៉ុស្តិ៍ 4',
  min: 'តិចបំផុត',
  betting1:'BET',
  max: 'ធំបំផុត',
  log:'ចូល',
  logging: 'កំពុងចូល',
  changeLanguage :'ប្តូរភាសារ',
  password: 'លេខសម្ងាត់',
  bet_between : 'ចន្លោះ',
  bet_small_large: 'តូច / ធំ',
    // start from here
    TIGER: "ខ្លា",
    DRAGON:"នាគ",
    BANKER:"BANKER",
    PLAYER:"PLAYER",
    
    gameIsClose:"ការភ្នាល់បានបិទ",
    draw:'ស្មើ',
    confirm:"យល់ព្រម",
    Close:"បិទ",
    Open:"បើក",
    Not_Open:"ឡាយ",
  fight_sign:"#",
    //sidebar
    user_center: "ព័ត៌មានអ្នកប្រើប្រាស់",
    all_report: "របាយការណ៍សរុប",
    cash_ins: "របាយការណ៌ដាក់ប្រាក់",
    withdrawals: "របាយការណ៌ដកប្រាក់",
    languages: "ភាសារ",
    other: "ផ្សេងៗ",
    logout: "ចាកចេញ",
    small:"តូច",
    large:"ធំ",
    return:"Return",
    date_time:'កាលបរិច្ឆេទ',
    start_date:'ថ្ងៃចាប់ផ្តើម',
    end_date:'ថ្ងៃបញ្ចប់',
    cast: 'ប្រាក់សងត្រឡប់',
    amount_win: 'ទឹកប្រាក់ឈ្នះ',
    Old_Balance:"តុល្យភាពចាស់",
    New_Balance:"តុល្យភាពថ្មី",
    Amount:'ទឹកប្រាក់',
    dateTime:'កាលបរិច្ឆេទ',
    cash:'សមតុល្យសាច់ប្រាក់',
    Rdeposit:'របាយការណ៍ដាក់ប្រាក់',
    Rwithdraw:'របាយការណ៍ដកប្រាក់',
    total_win_lose: 'សរុប ឈ្នះ/ចាញ់',
    all_statement:'របាយការណ៍រួម',
    selectCoin : "សូមចុចលើកាក់",
    _result:"លទ្ធផល៖ ",
    ticket:'សំបុត្រ',
    total_betting_an_invoice:'សរុបលុយកំពុងភ្នាល់ក្នុង១ ប៉ុង',
    total_betting_a_game:'សរុបលុយភ្នាល់ក្នុង១ ដៃ',
    total:"សរុប",
    credit: "លុយ",
    outstanding:"លុយភ្នាល់",
    no_data: "គ្មានទិន្នន័យ ...",
    previous:"លទ្ធផលចុងក្រោយ",
    lo_current_betting:"ទើបភ្នាល់",
    lo_previous_betting:"ភ្នាល់ដៃមុន",
    odd_even:'គូ/សេស',
    blue_red:'ខៀវ/ក្រហម',
    result_year: "លទ្ធផលតាមឆ្នាំ",
    result_small_big: "លទ្ធផលក្រាស់ស្តើង",
    result_range: "លទ្ធផលចន្លោះ",
    wait:"សូមរង់ចាំ",
    post_channel:"បុស្តិ៍",
    go_with_tail:"ចាក់អូសកន្ទុយ",
    go_with_head:"ចាក់អូសក្បាល",
    normal_betting:"ចាក់ធម្មតា",
    all_post:"គ្រប់បុស្ត៌",
    submit_betting:"ភ្នាល់",
    lottery_result:"លទ្ធផលឆ្នោត",
    game_close:"ការភ្នាល់បានបញ្ចប់",
    game_no:"ឆ្នោតលេខ:",
    not_enought_balance:"សូមបញ្ចូលលុយ",
    please_bet:'សូមជ្រើសរើសលេខ',
    SUCCESS:'ការភ្នាល់បានជោគជ័យ',
    bet_type:"ប្រភេទចាក់",
    bet_total: 'ទឹកប្រាក់សរុប',
    invoice_detail: "លំអិតវិក័យប័ត្រ",
    total_cast:"ប្រាក់សងត្រលប់",
    total_amount_win:"សរុបលុយសង",
    x_win:"ឈ្នះ",
    x_lose:"ចាញ់",
    tiger:"ខ្លា",
    dragon :'នាគ',
    player :"PLAYER",
    Banker :"BANKER",

    yuki_wipe : "ជូត",
    yuki_street : "ឆ្លូវ",
    yuki_karl : "ខាល",
    yuki_thao : "ថោះ",
    yuki_factory : "រោង",
    yuki_net : "ម្សាញ់",
    yuki_thin : "ស្តើង",
    yuki_thick : "ក្រាស់",
    yuki_mimi : "មមី",
    yuki_mom : "មមែ",
    yuki_vk : "វក",
    yuki_roka : "រកា",
    yuki_kor : "កុរ",
    yuki_odd : "សេស",
    yuki_pair : "គូ",
    yuki_red : "ក្រហម",
    yuki_blue : "ខៀវ",
    yuki_dog : "ច",
    yuki_null : "null",
    win :'ឈ្នះ',
    lose:"ចាញ់",
    win_lose:"ឈ្នះ/ចាញ់",
    print:"សំបុត្រ",
    boxing_MERON:'ក្រហម',
    boxing_WALA:'ខៀវ',
    boxing_draw:'ស្មើ',
    boxing_TIE:'ស្មើ',
    roulette_MERON:'ក្រហម',
    roulette_WALA:'ខ្មៅ',
    roulette_draw:'បៃតង',
    TIE:"ស្មើ",
    'ខ្លា':"ខ្លា",
    'មាន់':'មាន់',
    "ត្រី":'ត្រី',
    "បង្កង":"បង្កង",
    'ក្ដាម':"ក្ដាម",
    'ឃ្លោក':"ឃ្លោក",
    'PAIR BANKER':"គូ Banker",
    'PAIR PLAYER':"គូ PLAYER",
    undefined:"---",
    CANCEL :"CANCEL",
    bet_point :"បានចាក់",
    play :"លេង",
    bac4_MERON:'BANKER',
    bac4_WALA:'PLAYER',
    bac4_draw:'ស្មើ',
    bac4_TIE:'ស្មើ',
    
    roulette_TIE:'បៃតង',

};
