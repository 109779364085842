<template>
  <div>
      <!-- <span>JKJ</span> -->
      <v-app class="bgImage">
          <v-main>
              <v-container fluid fill-height>
                  <v-layout align-center justify-center>
                      <v-flex xs10 sm3>
                          <v-card class="elevation-15 formLogin">
                              <div class="formLogin-bg"></div>
                              <v-img src="images/logo.png" class="logo"></v-img>
                              <div class="empty-space"></div>
                              <v-form ref="form" lazy-validation @submit.prevent="login">
                                  <v-card-text style="padding: 2px 16px !important">
                                      <v-text-field style="color: #ffffff !important"
                                                    dense
                                                    outlined
                                                    prepend-inner-icon="person"
                                                    v-model="user.user_name"
                                                    label="Username"
                                                    :rules="rules.required"
                                                    required
                                                    type="text"
                                                    autocomplete="none"
                                                  ></v-text-field>
                                      <v-text-field v-model="user.password" 
                                                    dense                                                     
                                                    outlined
                                                    prepend-inner-icon="lock"
                                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="rules.required"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    name="input-10-1"
                                                    counter
                                                    label="Password"
                                                    autocomplete="new-password"
                                                    @click:append="showPassword = !showPassword"
                                                    style="color: #ffffff !important"></v-text-field>
                                  </v-card-text>
                                  <v-card-actions>                                    
                                          <v-col class="col-12" sm="12" style="padding-left:0px;padding-right:0px">
                                              <v-btn :loading="loading"
                                                     type="submit"
                                                     :disabled="loading"
                                                     block
                                                     outlined
                                                     style="border-radius:30px">
                                                  {{ $t("title.login") }}
                                              </v-btn>
                                          </v-col>                                        
                                  </v-card-actions>
                              </v-form>
                              <v-row>
                                  <v-col class="col-12" md="12" sm="12" lg="12">
                                      <div class=" text-center lang">
                                          <v-btn class="mx-2" fab dark color="primary" small @click="changeLangKh">
                                              <v-img src="images/kh-flag.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark color="purple" small @click="changeLangEn">
                                              <v-img src="images/en-flag.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark small color="purple" @click="changeLangTh">
                                              <v-img src="images/thai-flag.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark small color="purple" @click="changeLangVN">
                                              <v-img src="images/VN-flag.png" height="40" width="40"></v-img>
                                          </v-btn>
                                      </div>
                                  </v-col>
                                  <!-- <v-col class="col-12" sm="12" lg="6">
                                      <div class="bank">
                                          <v-btn class="mx-2" fab dark color="primary" small>
                                              <v-img src="images/ABA.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark color="purple" small>
                                              <v-img src="images/Wing.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark small color="purple">
                                              <v-img src="images/Acleda.png" height="40" width="40"></v-img>
                                          </v-btn>

                                          <v-btn class="mx-2" fab dark small color="purple">
                                              <v-img src="images/Truemoney.png" height="40" width="40"></v-img>
                                          </v-btn>
                                      </div>
                                  </v-col> -->
                              </v-row>
                          </v-card>
                      </v-flex>
                  </v-layout>
              </v-container>
          </v-main>
      </v-app>
     
  </div>
</template>

<script>
  import "@/assets/css/login.css";
  import { mapActions, mapMutations} from "vuex";
  export default {
      data() {
          return {
              user: {
                  user_name: null,
                  password: null,
              },
              select: ['English'],
              showPassword: false,
              password: 'Password',
              loading: false,
              error: false,
              rules: {
                  required: [(value) => !!value || 'Required.'],
                  min: v => (v && 0 !== v.length >= 6) || 'Min 6 characters',
                  emailMatch: () => (`The email and password you entered don't match`),
              },
              url: "https://www.dropbox.com/s/3tsk1g3t9bpzr99/SBC-Mobile_prodRelease_1.0.0.apk?dl=1",
          }
      },
      mounted() {
          this.changeLangKh() //set defaul language to khmer
      },
      methods: {
          reset() {
              this.$refs.form.reset();
          },
        _findChannelType(url){
            switch(url){
                case 'LOTTO' : return 90;
                case 'YUKI' : return 91;
                case 'LOTTERY' : return 92;
            }
        },
          async login() {
              if (this.$refs.form.validate()) {
                  try {
                    this.loading = true;
                    this.user.app_id = process.env.VUE_APP_ID
                    const response = await this.fetchLogIn(this.user);
                      if (response.data.code) {
                          const { token, user } = response.data.data;
                          if (user.is_suspend) {
                              this.$toastr.e(`Your account have been blooked!`)
                          } else {
                            this.$cookies.set("sbc_token", "Bearer " + token, 0);
                            this.$cookies.set('sbc_channel_type', user.channel_list ? user.channel_list[0] : '');
                            this.$cookies.set('sbc_home_channel', user.home_channel ? user.home_channel[0] : '');
                            this.$cookies.set('sbc_live_channel', user.live_channel ? user.live_channel[0] : '');
                            this.$cookies.set('sbc_slot_channel', user.slot_channel ? this._findChannelType(user.slot_channel[0]) : '');
                            this.$cookies.set("sbc_user", user);
                            this.UPDATE_CHANNEL_ID(user.channel_list[0])
                            this.UPDATE_CHANNEL_LIST(user.channel_list)
                            this.$router.push({ path: "/home" });
                              //this.myInfo();
                          }
                      } else {
                          this.$toastr.e(`${response.data.message.descriptions}`)
                          this.error = true;
                      }
                  } catch (error) {
                      this.$toastr.e(`${error}`)
                      console.log('ERROR:', error);
                      this.error = true;
                      return error;
                  } finally {
                      this.loading = false;
                  }
              }
          },
          async myInfo() {
              try {
                  this.loading = true;
                  const response = await this.fetchMyInfo();
                  if (response.data.code) {
                    const { user_name, balance, currency_type } = response.data.data;
          this.$cookies.set("user_name", user_name);
          this.$cookies.set("balance", balance);
          this.$cookies.set("currency_type", currency_type);
                  }
                  else {
                      this.error = true;
                  }
              } catch (error) {
                  this.error = true;
                  return error;
              } finally {
                  this.loading = false;
              }
          },
          changeLangEn() {
              let lang = this.$cookies.get("local");
              this.$i18n.locale = lang && lang == "english" ? "english" : "english";
              this.$cookies.set("local", this.$i18n.locale);
          },
          changeLangKh() {
              let lang = this.$cookies.get("local");
              this.$i18n.locale = lang && lang == "khmer" ? "khmer" : "khmer";
              this.$cookies.set("local", this.$i18n.locale);
          },
          changeLangTh() {
              let lang = this.$cookies.get("local");
              this.$i18n.locale = lang && lang == "thai" ? "thai" : "thai";
              this.$cookies.set("local", this.$i18n.locale);
          },
          changeLangVN() {
              let lang = this.$cookies.get("local");
              this.$i18n.locale = lang && lang == "vietnam" ? "vietnam" : "vietnam";
              this.$cookies.set("local", this.$i18n.locale);
          },
          ...mapActions("$_auth", ["fetchLogIn"]),
          // ...mapMutations("$_modules", ["UPDATE_USER_INFO"]),
          ...mapActions("$_myinfo", ["fetchMyInfo"]),
          ...mapMutations("$_modules", [
      "UPDATE_CHANNEL_ID",
      "UPDATE_CHANNEL_LIST",
    ]),
      },
  }
</script>

<style>
.theme--light.v-icon {
    color: white!important;
}
  .bgImage {
      background-image: url(*/../../images/images/wabanner.png) !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-color: #E3E3E3 !important;
  }

  .bg {
      background-color: #2e3466 !important;
  }

  @media (max-width:480px) {
      .screenMobile {
          width: 400px;
          padding: 0 25px 0 25px;
          margin-left: -2px !important;
          justify-content: center !important;
      }

      .noImageMobile {
          display: none !important;
      }
  }

  .theme--light.v-input, .theme--light.v-input input, .theme--light.v-input textarea {
      color: grey !important;
      border: none !important;
  }
  .v-text-field input{
      padding:0px !important;
  }
  
</style>
